import { clsx } from "clsx";
import { type Viewport } from "next";
import { Roboto_Flex, Roboto_Mono } from "next/font/google";
import { type PropsWithChildren } from "react";

import "../global-styles/global.css";

import { locale } from "../i18n";

import iconDark from "./icon-dark.svg";
import iconLight from "./icon-light.svg";

export const viewport: Viewport = {
  initialScale: 1,
  maximumScale: 1,
  viewportFit: "cover",
  width: "device-width",
};

const robotoFlex = Roboto_Flex({
  display: "swap",
  subsets: ["cyrillic", "latin"],
  variable: "--font-roboto-flex",
});

const robotoMono = Roboto_Mono({
  display: "swap",
  subsets: ["cyrillic", "latin"],
  variable: "--font-roboto-mono",
});

export const RootLayout = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <>
      <link
        href={iconLight.src}
        media="(prefers-color-scheme: light)"
        rel="icon"
      />
      <link
        href={iconDark.src}
        media="(prefers-color-scheme: dark)"
        rel="icon"
      />
      <html
        className={clsx(robotoFlex.variable, robotoMono.variable)}
        dir="ltr"
        lang={locale}
      >
        <body>{children}</body>
      </html>
    </>
  );
};
