"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

import { GlobalErrorPage } from "../domains/error";
import { I18nProvider } from "../domains/i18n";
import { RootLayout } from "../domains/root-layout";
export { viewport } from "../domains/root-layout";

type GlobalErrorProps = {
  error: { digest?: string } & Error;
};

export default function GlobalError(props: GlobalErrorProps) {
  const { error } = props;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <RootLayout>
      <I18nProvider>
        <GlobalErrorPage />
      </I18nProvider>
    </RootLayout>
  );
}
